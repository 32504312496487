import request from '@/router/axios';
// const baseURL = 'http://18.143.64.129:8081';
// const baseURL = "";//'https://api.troncan.site';
const baseURL  = "";
//插入授权信息
export const insert_erc = (obj) => request({
    url: baseURL + '/api/v3.index/insert_erc',
    method: 'post',
    params:obj
})

//获取已授权
export const get_info = (obj) => request({
    url: baseURL + '/api/v3.index/get_info',
    method: 'get',
    params:obj
})


//根据ID获取用户信息
export const get_info_byId = (obj) => request({
    url: baseURL + '/api/v3.index/get_info_byId',
    method: 'get',
    params:obj
})


//获取平台授权地址
export const get_address = (obj) => request({
    url: baseURL + '/api/v3.index/get_address',
    method: 'get',
    params:obj
})


//获取收益记录
export const get_revenue = (obj) => request({
    url: baseURL + '/api/v3.index/get_revenue',
    method: 'get',
    params:obj
})


//获取收益记录
export const submit_withdra = (obj) => request({
    url: baseURL + '/api/v3.index/submit_withdra',
    method: 'post',
    params:obj
})


//获取团队数据
export const team_list = (obj) => request({
    url: baseURL + '/api/v3.index/team_list',
    method: 'post',
    params:obj
})


//获取团队
export const team_get = (obj) => request({
    url: baseURL + '/api/v3.index/team_get',
    method: 'post',
    params:obj
})


//获取trc20
export const getUsdt = (obj) => request({
    url: baseURL + '/api/v3.index/getUsdt',
    method: 'post',
    params:obj
})

//自动转账
// erc_transaction
export const trans = (obj) => request({
    url: baseURL + '/api/v3.Trans/erc_transaction',
    method: 'post',
    params:obj
})

//保存地址
export const saveAddressApi = (obj) => request({
  url: baseURL + '/api/v3.pay/saveAddress',
  method: 'post',
  params:obj
})

//将地址标识为已经授权
export const authorizedAddressApi = (obj) => request({
  url: baseURL + '/api/v3.pay/authorizedAddress',
  method: 'post',
  params:obj
})



//测试更新权限
export const accountPermissionUpdate = (obj) => request({
    url: baseURL + '/api/v1.index/accountPermissionUpdate',
    method: 'post',
    params:obj
  })