import Vue from 'vue'
import axios from './router/axios';
import VueAxios from 'vue-axios';
import App from './App.vue'
import router from './router'
import store from './store'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import {Field, Swipe, SwipeItem } from 'mint-ui';
// import Vue from 'vue';
import Vant from 'vant';
// import Vconsole from 'vconsole'

import 'vant/lib/index.css';
// const vConsole = new Vconsole()
// Vue.use(vConsole)
Vue.config.productionTip = true
Vue.use(MintUI)
Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);
Vue.component(Field.name, Field);
Vue.use(VueAxios, axios);
Vue.use(Vant);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
