<template>
  <div>
    <mt-popup v-model="copyVisible" class="popup">
      <div>
        Add {{kefu_title}} Number<span>{{ kefu_num }}</span>
      </div>
      <mt-button :data-clipboard-text="kefu_num" @click="copy" id="copy_text" type="primary" size="small">copy
      </mt-button>
    </mt-popup>
    <div class="kfxts">
        <!-- <span @click="href('http://t.me/bbm14')"> -->

        <span  v-for="(item,index) in kfList" :key="index" >
          <a v-if="dl_id == 55 || dl_id == 61" :href="item"  target="_top">
            <img v-if="index == 1" src="../../assets/telegram_logo.png" alt="" />
            <img v-else src="../../assets/kefu.png" alt="" />
          </a>
           <a v-else href="javascript:;" @click="popup(item,index)">
            <img v-if="index == 1" src="../../assets/telegram_logo.png" alt="" />
            <img v-else src="../../assets/kefu.png" alt="" />
          </a>
        </span>


   
      

 <!--       <span @click="popup()" v-if="kfList.length == 0">
           <img src="../../assets/kefu.png" alt="" />
         </span> -->

       <!-- <span @click="popup()">
          <img src="../../assets/telegram_logo.png" alt="" />
        </span> -->

        <!-- <span @click="add_chatinline()" target="_blank"  >
          <img src="../assets/kefu.png" alt="" />
        </span> -->
      </div>
    </div>
  </div>

</template>

<script>
  import {
    get_info_byId
  } from "../api/index";
  import Clipboard from "clipboard";
  export default{
    name:"kefu",
    props:['kefunum','dlid'],
    data(){
      return{
         copyVisible: false,
         kefu_title:"Whatsapp",
         kfList:["1 (672) 218-6853"],
         kefu_num: this.kefunum == null ? "1 (672) 218-6853" : this.kefunum,
         dl_id:this.dlid == 0 ? "0" : this.dlid
      }
    },
    methods: {
      printKefuNum(num = "1 (672) 218-6853"){
        var arr = num.split(',');
        console.log(this.dl_id);
        this.kfList = arr;
        if(arr.length > 1){
          console.log(arr);
        }else{
          this.kefu_num = num;
        }

      },
      copy() {
        var _this = this;
        var clipboard = new Clipboard("#copy_text");

        clipboard.on("success", (e) => {
          // alert("dd");
          this.copyVisible = false;
          // 释放内存
          Toast("Copy success");
          clipboard.destroy();
        });
        clipboard.on("error", (e) => {
          // 不支持复制
          Toast({
            message: "该浏览器不支持自动复制",
            type: "warning",
          });
          // 释放内存
          clipboard.destroy();
        });
      },
      popup(e,n) {
        // console.log(e);
        if(n == 1){
          this.kefu_title == "Telegram";
          this.kefu_num = e;
        }else{
          this.kefu_num = "+"+e;
        }

        this.copyVisible = true;
      },

    },
  }
</script>

<style scoped>

.kfxts {
  position: fixed;
  top: 30%;
  right: 0;
  width: 3rem;
  height: 3rem;
  right: 5%;
}
.kfxts img {
  display: block;
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
}
.popup {
  padding: 10vw 2vw;
  border-radius: 25px;
}
.popup div span {
  margin: 2vw 0 3vw 0;
  display: block;
  color: red;
}
</style>
